<template>
  <section class="hero">
    <div class="container">
      <div class="hero__inner">
        <div class="heading">
          <h1 class="title fs64 pb20 m0a">{{ title }}</h1>
          <p class="desc fs16 m0a">{{ desc }}</p>
        </div>
        <AppSubmission :title="$t('home.app-banner.title')" :desc="$t('home.app-banner.desc')" />
        <WhatIsTeamPay
          :title="$t('home.whatIs.title')"
          :desc="$t('home.whatIs.body')"
          :linkText="$t('home.whatIs.linkText')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import WhatIsTeamPay from './WhatIsTeamPay.vue'
import AppSubmission from '@/components/AppSubmission/AppSubmission.vue'
export default {
  name: 'Team Hero',
  props: {
    title: String,
    desc: String,
  },
  components: { AppSubmission, WhatIsTeamPay },
}
</script>

<style scoped>
.hero {
  padding-top: 90px;
}
.hero__inner {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .hero {
    padding-top: 90px;
  }
}
</style>
