<template>
  <div class="menu">
    <div class="menu__inner">
      <MobileSearch @submitSearch="handleSearch" />
      <div class="mobile__nav">
        <ul class="menu__list">
          <li class="menu__list-item" v-for="item in keys" :key="item.key">
            <router-link class="menu__list-link" :to="$t(item.href)">
              {{ $t(item.key) }}
            </router-link>
          </li>
        </ul>
        <div class="menu__social">
          <a class="menu__social-link" href="https://www.facebook.com/Teampay.am" target="_blank">
            <FacebookIcon bg-color="#01425f" opacity="1" />
          </a>
          <a
            class="menu__social-link"
            href="https://www.linkedin.com/company/teampay-cjsc/"
            target="_blank"
          >
            <LinkedinIcon bg-color="#01425f" opacity="1" />
          </a>
        </div>
      </div>
      <BurgerLanguageSwitcher />
    </div>
  </div>
</template>

<script>
import BurgerLanguageSwitcher from './BurgerLanguageSwitcher.vue'
import MobileSearch from '../Search/MobileSearch.vue'
import FacebookIcon from '@/components/Icons/FacebookIcon.vue'
import LinkedinIcon from '@/components/Icons/LinkedinIcon.vue'
export default {
  name: 'Mobile Menu',
  components: { LinkedinIcon, FacebookIcon, BurgerLanguageSwitcher, MobileSearch },
  data() {
    return {
      keys: [
        {
          key: 'nav.services.title',
          href: 'nav.services.href',
        },
        {
          key: 'nav.about_us.title',
          href: 'nav.about_us.href',
        },
        {
          key: 'nav.contact.title',
          href: 'nav.contact.href',
        },
      ],
    }
  },
  methods: {
    handleSearch() {
      this.$emit('submitSearch')
    },
  },
}
</script>

<style scoped>
.menu {
  display: none;
}
@media screen and (max-width: 1024px) {
  .menu {
    display: block;
    background-image: url('../../assets/img/images/burger-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 56px;
    transform: translateX(200%);
    transition: transform 0.3s linear;
    border-top: 2px solid var(--teamLightGray);
  }
  .menu.open {
    transform: translateX(0);
    overflow: hidden;
  }
  .menu__inner {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-items: center;
  }

  .menu__list {
    text-align: center;
    max-width: 380px;
    margin: 0 auto;
  }
  .menu__list-item {
    margin-bottom: 27px;
  }
  .menu__list-link {
    font-weight: bold;
  }
  .menu__list-link.router-link-exact-active {
    position: relative;
  }
  .menu__list-link.router-link-exact-active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--teamCyan);
    bottom: -10px;
    left: 0;
  }
  .mobile__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 375px;
    margin-top: 100px;
  }
  .menu__social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    position: relative;
    width: 100%;
  }
  .menu__social::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #bababa;
    bottom: -21px;
  }
}
</style>
