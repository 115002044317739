<template>
  <div class="about-services">
    <div class="about-services__head">
      <h3 class="title fs32 pb30">{{ title }}</h3>
      <p class="desc fs16">{{ desc }}</p>
    </div>
    <div class="about-services__content">
      <div class="about__left">
        <h4 class="title fs32">{{ innerTitle }}</h4>
        <p class="desc fs16">{{ innerText }}</p>
        <TeamPayLinkButton :title="$t('about.services.linkText')" :to="{ name: 'Services' }" />
      </div>
      <div class="about__right">
        <img class="about__img" src="@/assets/img/images/about-services.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import TeamPayLinkButton from '../UI/TeamPayLinkButton.vue'
export default {
  name: 'About Services',
  props: {
    title: String,
    desc: String,
    innerTitle: String,
    innerText: String,
  },
  components: { TeamPayLinkButton },
}
</script>

<style>
.about-services {
  padding-top: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1150px;
  width: 1150px;
}
.about-services__head {
  text-align: center;
  padding-bottom: 55px;
}

.about-services__head .desc {
  padding-bottom: 0;
}
.about-services__content {
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

@media screen and (min-width: 320px) and (max-width: 650px) {
  .about-services {
    max-width: 100%;
    padding-top: 50px;
  }
  .about-services__head {
    padding-bottom: 25px;
  }

  .about-services__content {
    flex-direction: column;
  }
  .about-services__content .desc {
    padding-bottom: 0;
    text-align: center;
  }
  .about-services__content .btn {
    margin: 0 auto;
  }
}
@media screen and (min-width: 651px) and (max-width: 1080px) {
  .about-services {
    max-width: 100%;
  }
}
</style>
