import { createWebHistory } from 'vue-router'
import { createLangRouter } from 'vue-lang-router'
import translations from '../lang'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
const Branches = () => import('../views/Branches.vue')
const UsefulInformation = () => import('../views/UsefulInformation.vue')
const DeleteAccount = () => import('../views/DeleteAccount.vue')
import GeneralInfo from '../views/GeneralInfo.vue'
import PageNotFound from '../views/PageNotFound.vue'
import SearchPage from '../views/SearchPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/branches',
    name: 'Branches',
    component: Branches,
  },
  {
    path: '/information',
    name: 'Useful Information',
    component: UsefulInformation,
  },
  {
    path: '/basic-info',
    name: 'General Info',
    component: GeneralInfo,
  },
  {
    path: '/search',
    name: 'Search page',
    component: SearchPage,
    props: (route) => ({ query: route.query.q }),
  },
  {
    path: '/delete-account',
    name: 'Delete account',
    component: DeleteAccount,
  },
  {
    path: '/:pathMatch(.*)',
    name: 'Page Not Found',
    component: PageNotFound,
  },
]

const langRouterOptions = {
  defaultLanguage: '/hy',
  translations,
}
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
}
const router = createLangRouter(langRouterOptions, routerOptions)

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  if (nearestWithTitle && nearestWithTitle.meta.title !== '') {
    document.title = `${nearestWithTitle.meta.title} | Team Pay`
  } else if (previousNearestWithMeta) {
    document.title = `Team Pay`
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el),
  )

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
