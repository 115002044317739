<template>
  <section class="app-banner">
    <div class="app-banner__inner">
      <div class="left">
        <div class="content">
          <h2 class="title fs32 pb30">{{ title }}</h2>
          <p class="desc fs16 pb30">{{ desc }}</p>
          <div class="app-banner__links">
            <a
              v-for="(link, index) in links"
              :key="index"
              :href="link.href"
              :class="{ 'qr-link': !!link.type }"
              @click="linkClick(link.type, $event)"
            >
              <img :src="link.image" :alt="link.alt" />
            </a>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="app-banner__list">
          <div class="app-banner__item" v-for="item in bannerItems" :key="item.text">
            <img :src="item.image" :alt="item.text" />
            <span>{{ $t(item.text) }}</span>
          </div>
        </div>
        <div class="app-banner__image">
          <img src="@/assets/img/images/app-notif.png" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['title', 'desc'],
  data() {
    return {
      links: [
        {
          image: require('../../assets/img/icons/app-store.svg'),
          href: 'https://appurl.io/YWuAaXGhSm',
          alt: 'App Store',
        },
        {
          image: require('../../assets/img/icons/play-store.svg'),
          href: 'https://appurl.io/YWuAaXGhSm',
          alt: 'Play Store',
        },
        {
          image: require('../../assets/img/images/qr-link.jpg'),
          type: 'qr',
          alt: 'QR code',
        },
      ],
      bannerItems: [
        {
          text: 'home.app-banner.items.autopayments',
          image: require('../../assets/img/icons/autopayment.svg'),
        },
        {
          text: 'home.app-banner.items.groupPayments',
          image: require('../../assets/img/icons/group-payments.svg'),
        },
        {
          text: 'home.app-banner.items.benefits',
          image: require('../../assets/img/icons/benefits.svg'),
        },
      ],
    }
  },
  methods: {
    linkClick(type, event) {
      if (!!type) event.preventDefault()
    },
  },
}
</script>

<style lang="css" scoped>
.title {
  text-transform: capitalize;
}
.app-banner {
  padding-bottom: 100px;
  overflow: hidden;
}

.app-banner__inner {
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 24px rgba(192, 192, 192, 0.08);
  height: 480px;
}

.left {
  flex: 1;
}

.content {
  padding: 64px;
}

.right {
  height: 100%;
  flex: 1;
  position: relative;
}

.app-banner__list {
  position: absolute;
  bottom: 30px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.app-banner__item {
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  padding: 12px;
  border-radius: 16px;
  width: 260px;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.3s ease-in-out;
}

.app-banner__item:hover {
  transform: scale(1.1);
}

.app-banner__item span {
  font-size: 14px;
}

.app-banner__image {
  display: block;
  max-width: 390px;
  margin-left: auto;
}

.app-banner__image img {
  display: block;
  width: 100%;
  height: auto;
}

.app-banner__links {
  display: flex;
  gap: 12px;
  align-items: center;
}

.app-banner__links a {
  transition: all 0.3s ease-in-out;
}

.app-banner__links a:hover {
  transform: scale(1.1);
}

.app-banner__links img {
  display: block;
  width: 100%;
}

.qr-link {
  display: block;
  width: 44px;
  border-radius: 5px;
  border: 1.5px solid #18425d;
}

.qr-link img {
  padding: 5px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .content {
    padding: 46px 24px 40px 24px;
    max-width: 380px;
    text-align: center;
  }

  .app-banner__inner {
    flex-direction: column;
    gap: 0;
    height: auto;
  }

  .app-banner__links {
    gap: 6px;
  }

  .app-banner__item {
    padding: 6px;
  }

  .app-banner__image {
    max-width: 70%;
    margin-left: auto;
    margin-right: -32px;
  }

  .app-banner__item span {
    font-size: 12px;
  }
}

@media screen and (min-width: 551px) and (max-width: 728px) {
  .app-banner__inner {
    flex-direction: column;
    gap: 0;
    height: auto;
  }

  .right {
    width: 100%;
    padding-inline: 20px;
  }

  .app-banner__list {
    bottom: 50%;
    transform: translateY(50%);
  }

  .app-banner__image {
    max-width: 60%;
    margin-left: auto;
    margin-right: -32px;
  }
}

@media screen and (min-width: 729px) and (max-width: 768px) {
  .app-banner__list {
    position: static;
  }

  .app-banner__item {
    padding: 6px;
    max-width: 225px;
    width: 100%;
  }

  .right {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
