<template>
  <main style="padding-top: 90px">
    <section class="contact">
      <div class="container">
        <div class="contact__inner">
          <div class="heading">
            <h1 class="title fs64 pb30 m0a">{{ $t('contact.head.title') }}</h1>
            <p class="desc fs16 m0a">{{ $t('contact.head.body') }}</p>
          </div>
          <div class="contact__map">
            <img
              class="contact__map-image contact__map-image--desktop"
              src="@/assets/img/images/map-hq.jpg"
              alt="HQ"
            />
            <img
              class="contact__map-image contact__map-image--mobile"
              src="@/assets/img/images/map-hq-mobile.jpg"
              alt="HQ"
            />
          </div>
          <div class="contact__box">
            <ContactUsInfo />
          </div>
        </div>
        <p class="updated">{{ $t('updated') }} 14.03.2025</p>
      </div>
    </section>
  </main>
</template>

<script>
import ContactUsInfo from '@/components/ContactUs/ContactUsInfo.vue'
export default {
  name: 'Contact Us',
  components: { ContactUsInfo },
  metaInfo() {
    return {
      title: this.$t('meta.pages.contact.title') + ' | Team Pay',
      description: this.$t('meta.pages.contact.description'),
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.contact__inner {
  max-width: 1050px;
  margin: 0 auto;
}

.contact__map {
  padding-bottom: 16px;
}
.contact__map-image {
  max-width: 100%;
}
.contact__map-image--mobile {
  display: none;
}
.contact__box {
  display: flex;
  justify-content: space-between;
  gap: 31px;
}
@media screen and (min-width: 320px) and (max-width: 650px) {
  .contact__inner {
    max-width: 100%;
  }
  .contact__map {
    max-width: 100%;
    height: 200px;

    margin-bottom: 16px;
  }
  .contact__map-image--desktop {
    display: none;
  }
  .contact__map-image--mobile {
    display: block;
    max-width: 100%;
    height: 200px;
    margin: 0 auto;
    display: block;
  }
  .contact__box {
    flex-direction: column;
  }
}
</style>
