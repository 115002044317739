<template>
  <nav class="footer__nav" role="navigation" aria-label="Footer menu">
    <div class="footer__box">
      <span class="footer__title" @click="toggleAccordion1" :class="{ closed: !isOpen }">{{
        $t('footer.general.title')
      }}</span>
      <ul class="footer__list" :class="{ hidden: !isOpen }">
        <li class="footer__list-item">
          <router-link to="/basic-info#1" class="footer__list-link">{{
            $t('footer.general.list.legal')
          }}</router-link>
        </li>
        <li class="footer__list-item">
          <router-link to="/basic-info#2" class="footer__list-link">{{
            $t('footer.general.list.owners')
          }}</router-link>
        </li>
        <li class="footer__list-item">
          <router-link to="/basic-info#3" class="footer__list-link">{{
            $t('footer.general.list.reports')
          }}</router-link>
        </li>
        <li class="footer__list-item">
          <router-link to="/basic-info#4" class="footer__list-link">{{
            $t('footer.general.list.charter')
          }}</router-link>
        </li>
      </ul>
    </div>
    <div class="footer__box">
      <span @click="toggleAccordion2" class="footer__title" :class="{ closed: !isOpen2 }">
        {{ $t('footer.usefulInformation.title') }}
      </span>
      <ul class="footer__list" :class="{ hidden: !isOpen2 }">
        <li class="footer__list-item">
          <router-link to="/information#1" class="footer__list-link">
            {{ $t('footer.usefulInformation.list.financialMediator') }}
          </router-link>
        </li>
        <li class="footer__list-item">
          <router-link to="/information#2" class="footer__list-link">
            {{ $t('footer.usefulInformation.list.announcements') }}
          </router-link>
        </li>
        <li class="footer__list-item">
          <router-link :to="{ name: 'Branches' }" class="footer__list-link">
            {{ $t('footer.usefulInformation.list.branches') }}</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Footer Menu',
  data() {
    return {
      isOpen: false,
      isOpen2: false,
    }
  },
  methods: {
    toggleAccordion1() {
      this.isOpen = !this.isOpen
    },
    toggleAccordion2() {
      this.isOpen2 = !this.isOpen2
    },
  },
}
</script>

<style scoped>
.footer__nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}
.footer__box {
  max-width: 300px;
}
.footer__box:not(:first-of-type) {
  margin-left: 65px;
}
.footer__title {
  padding-bottom: 45px;
  font-weight: bold;
  display: block;
  width: 100%;
}
.footer__list-item {
  margin-bottom: 15px;
}
.footer__list-link {
  font-size: 13px;
  transition: all 0.3s ease;
}
.footer__list-link:hover {
  text-decoration: underline;
}
@media screen and (min-width: 320px) and (max-width: 900px) {
  .footer__nav {
    flex-direction: column;
    gap: 2px;
  }
  .footer__box {
    width: 100%;
    max-width: 100%;
  }
  .footer__box:not(:first-of-type) {
    margin-left: 0;
  }
  .footer__title {
    margin-left: calc(-1 * ((100vw - 100%) / 2));
    width: 100vw;
    position: relative;
    background-color: rgba(255, 255, 255, 0.08);
    box-shadow:
      0px 0.6px 2px rgba(0, 0, 0, 0.06),
      0px 3px 24px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 12px 20px;
    font-size: 14px;
  }
  .footer__title::after {
    content: '';
    border-color: rgba(255, 255, 255, 0.4) transparent transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    bottom: 0;
    content: '';
    height: 0;
    margin: auto 0;
    position: absolute;
    right: 20px;
    top: 0;
    width: 0;
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  .footer__title.closed::after {
    transform: rotate(0);
  }
  .footer__title.closed {
    margin-bottom: 0;
  }
  .footer__list {
    max-height: 1000px;
    transition: all 0.3s linear;
    padding-top: 24px;
    overflow: hidden;
  }
  .footer__list.hidden {
    max-height: 0;
    transition: all 0.3s linear;
    padding-top: 0;
  }
}

@media screen and (min-width: 901px) and (max-width: 1080px) {
  .footer__box:not(:first-of-type) {
    margin-left: 25px;
  }
  .footer__nav {
    gap: 20px;
  }
}
</style>
