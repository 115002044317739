<template>
  <div id="fb-root"></div>
  <div id="fb-customer-chat" class="fb-customerchat"></div>
</template>

<script>
export default {
  name: 'FacebookMessanger',
  mounted() {
    const chatbox = document.getElementById('fb-customer-chat')
    chatbox.setAttribute('page_id', '104466452182120')
    chatbox.setAttribute('attribution', 'biz_inbox')
    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: 'v13.0',
      })
    }

    ;(function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  },
}
</script>
