<template>
  <div class="svg-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="16" :fill="bgColor" :fill-opacity="opacity" />
      <path
        d="M13.5528 27V17.1462H11V13.5983H13.5528V10.568C13.5528 8.18677 15.0607 6 18.5353 6C19.9421 6 20.9824 6.13765 20.9824 6.13765L20.9004 9.45074C20.9004 9.45074 19.8395 9.4402 18.6818 9.4402C17.4288 9.4402 17.228 10.0296 17.228 11.0078V13.5983H21L20.8359 17.1462H17.228V27H13.5528Z"
        :fill="iconColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'FacebookIcon',
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    iconColor: {
      type: String,
      default: 'white',
    },
    opacity: {
      type: String,
      default: 0.16,
    },
  },
}
</script>

<style scoped>
.svg-icon {
  display: inline-block;
}
</style>
