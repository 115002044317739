<template>
  <metainfo></metainfo>
  <Header />
  <router-view v-if="isLoaded" />
  <LoadingSpinner v-else />
  <Footer />
  <Messanger />
</template>

<script>
import Header from './components/Header/Header.vue'
import Footer from './components/Footer/Footer.vue'
import LoadingSpinner from './components/UI/LoadingSpinner.vue'
import Messanger from './components/FacebookBot/Messanger.vue'

export default {
  components: {
    Header,
    Footer,
    LoadingSpinner,
    Messanger,
  },
  data() {
    return {
      isLoaded: false,
      currentLanguage: localStorage.getItem('VueAppLanguage'),
    }
  },
  created() {
    setTimeout(() => (this.isLoaded = true), 100)
    if (!this.currentLanguage) {
      localStorage.setItem('VueAppLanguage', 'hy')
    } else {
      return this.currentLanguage
    }
  },
}
</script>

<style></style>
