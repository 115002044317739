<template>
  <main>
    <TeamHero :title="$t('home.hero.title')" :desc="$t('home.hero.body')" />
    <TeamPayServices :title="$t('home.services.title')" :desc="$t('home.services.body')" />
    <BranchesMap :title="$t('home.branches.title')" :desc="$t('home.howItWorks.body')" />
  </main>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import TeamHero from '@/components/Hero/TeamHero.vue'
import TeamPayServices from '@/components/TeamPayServices/TeamPayServices.vue'
import LoadingSpinnerVue from '@/components/UI/LoadingSpinner.vue'
import AppSubmission from '@/components/AppSubmission/AppSubmission.vue'

const BranchesMap = defineAsyncComponent({
  loader: () => import('@/components/BranchesMap/BranchesMap.vue'),
  loadingComponent: LoadingSpinnerVue,
})
export default {
  name: 'Home',
  components: {
    AppSubmission,
    TeamHero,
    TeamPayServices,
    BranchesMap,
  },
  metaInfo() {
    return {
      title: this.$t('meta.pages.home.title'),
      description: this.$t('meta.pages.home.description'),
    }
  },
}
</script>
