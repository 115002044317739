<template>
  <div class="services__box">
    <div class="accordion services__accordion">
      <div
        class="services__accordion-item"
        v-for="(type, index) in serviceGroups.types"
        :key="type"
      >
        <button class="accordion__btn" @click="handleToggles(type.type, index)">
          <span class="accordion-left">
            <img :src="type.typeImg" alt="" class="accordion-icon" />
            <span class="accordion-title">{{ $t(type.type) }}</span>
          </span>
          <span class="accordion-right">
            <span class="accordion-desc desktop">
              {{ $t('services.accordion.seeMore') }}
            </span>
            <img
              class="accordion-arrow"
              src="@/assets/img/utils/arrow-dark.svg"
              alt=""
              :class="clickedType === type.type ? 'rotated' : ''"
            />
          </span>
        </button>
        <div
          class="accordion__content"
          :class="clickedType === type.type ? 'active' : ''"
          ref="accordionContent"
        >
          <table class="services__table desktop">
            <thead class="services__table-head">
              <tr>
                <th class="services__table-title">
                  {{ $t('services.accordion.serviceProvider') }}
                </th>
                <th class="services__table-title">
                  {{ $t('services.accordion.fee') }}
                </th>
                <th class="services__table-title">
                  {{ $t('services.accordion.maxSum') }}
                </th>
                <th class="services__table-title">
                  {{ $t('services.accordion.transactionPeriod') }}
                </th>
              </tr>
            </thead>
            <tbody v-for="provider in type.providers" :key="provider">
              <tr class="services__table-row">
                <td class="services__table-desc td-first">
                  <img class="services__table-logo" :src="provider.img" :alt="provider.name" />
                  {{ $t(provider.name) }}
                </td>
                <td class="services__table-desc td-fee">
                  <ul class="services__table-list">
                    <li v-for="commission in provider.commission" :key="commission">
                      {{ $t(commission) }}
                    </li>
                  </ul>
                </td>
                <td class="services__table-desc">{{ provider.maxSum }} ֏</td>
                <td class="services__table-desc">{{ $t(provider.period) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="services__list-box mobile" v-for="provider in type.providers" :key="provider">
            <div class="services__list-row">
              <div class="services__list-name">
                <img class="services__table-logo" :src="provider.img" alt="" />
              </div>
              <p class="services__list-text">{{ provider.name }}</p>
            </div>
            <div class="services__list-row">
              <div class="services__list-name">
                <span> {{ $t('services.accordion.fee') }} </span>
              </div>
              <div class="services__list-text">
                <ul class="services__table-list">
                  <li
                    class="service__table-item"
                    v-for="commission in provider.commission"
                    :key="commission"
                  >
                    {{ $t(commission) }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="services__list-row">
              <div class="services__list-name">
                <span> {{ $t('services.accordion.maxSum') }}</span>
              </div>
              <p class="services__list-text">{{ provider.maxSum }} ֏</p>
            </div>
            <div class="services__list-row">
              <div class="services__list-name">
                <span> {{ $t('services.accordion.transactionPeriod') }} </span>
              </div>
              <p class="services__list-text">{{ $t(provider.period) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="updated">{{ $t('updated') }} 14.03.2025</p>
  </div>
</template>

<script>
export default {
  name: 'Services Accordion',
  data() {
    return {
      serviceGroups: {
        types: [
          {
            type: 'services.accordion.serviceTypes.mobile',
            typeImg: require('../../assets/img/services/mobile_services.png'),
            providers: [
              {
                name: 'services.accordion.serviceNames.team',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/team.png'),
              },
              {
                name: 'services.accordion.serviceNames.ucom',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/ucom.jpg'),
              },
              {
                name: 'services.accordion.serviceNames.viva',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/viva.jpg'),
              },
            ],
          },
          {
            type: 'services.accordion.serviceTypes.fixed',
            typeImg: require('../../assets/img/services/fixed_services.jpg'),
            providers: [
              {
                name: 'services.accordion.serviceNames.team',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/team.png'),
              },
              {
                name: 'services.accordion.serviceNames.ovio',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/ovio.png'),
              },
              {
                name: 'services.accordion.serviceNames.viva',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/viva.jpg'),
              },
              {
                name: 'services.accordion.serviceNames.ucom',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/ucom.jpg'),
              },
            ],
          },
          {
            type: 'services.accordion.serviceTypes.utilities',
            typeImg: require('../../assets/img/services/utility_services.png'),
            providers: [
              {
                name: 'services.accordion.serviceNames.ena',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/electricity.png'),
              },
              {
                name: 'services.accordion.serviceNames.gasMain',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/gas.png'),
              },
              {
                name: 'services.accordion.serviceNames.gasService',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/gas.png'),
              },
              {
                name: 'services.accordion.serviceNames.veolia',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/veolia.png'),
              },
            ],
          },
          {
            type: 'services.accordion.serviceTypes.internet',
            typeImg: require('../../assets/img/services/internet_services.png'),
            providers: [
              {
                name: 'services.accordion.serviceNames.team',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/team.png'),
              },
              {
                name: 'services.accordion.serviceNames.ucom',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/ucom.jpg'),
              },
              {
                name: 'services.accordion.serviceNames.viva',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/viva.jpg'),
              },
              {
                name: 'services.accordion.serviceNames.interactive',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/interactive.png'),
              },
              {
                name: 'services.accordion.serviceNames.ovio',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/ovio.png'),
              },
              {
                name: 'services.accordion.serviceNames.arpinet',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/arpinet.png'),
              },
              {
                name: 'services.accordion.serviceNames.yournet',
                commission: ['services.accordion.commission.general'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/yournet.png'),
              },
            ],
          },
          {
            type: 'services.accordion.serviceTypes.other',
            typeImg: require('../../assets/img/services/other_services.png'),
            providers: [
              {
                name: 'services.accordion.serviceNames.parking',
                commission: [
                  'services.accordion.commission.parking.lineOne',
                  'services.accordion.commission.parking.lineTwo',
                ],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/parking.png'),
              },
              {
                name: 'services.accordion.serviceNames.parkingFine',
                commission: ['services.accordion.commission.parkingFine'],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/parking.png'),
              },
              {
                name: 'services.accordion.serviceNames.rdFine',
                commission: [
                  'services.accordion.commission.parking.lineOne',
                  'services.accordion.commission.parking.lineTwo',
                ],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/police.png'),
              },
              {
                name: 'services.accordion.serviceNames.rdCameraFine',
                commission: [
                  'services.accordion.commission.roadPoliceFine.lineOne',
                  'services.accordion.commission.roadPoliceFine.lineTwo',
                  'services.accordion.commission.roadPoliceFine.lineThree',
                  'services.accordion.commission.roadPoliceFine.lineFour',
                ],
                maxSum: '400,000',
                period: 'services.accordion.time',
                img: require('../../assets/img/services/police.png'),
              },
            ],
          },
        ],
      },
      clickedType: '',
    }
  },
  methods: {
    toggleAccordion(key) {
      if (this.clickedType !== key) {
        this.clickedType = key
      } else {
        this.clickedType = ''
      }
    },
    toggleExpand(type, idx) {
      this.$refs.accordionContent.forEach((acc, index) => {
        if (idx === index && type === this.clickedType) {
          acc.style.flexBasis = `${25 + acc.scrollHeight}px`
        } else {
          acc.style.flexBasis = '0px'
        }
      })
    },
    handleToggles(type, index) {
      this.toggleAccordion(type)
      this.toggleExpand(type, index)
    },
  },
}
</script>

<style scoped>
.services__accordion-item {
  background-color: #fff;
  padding: 9px 16px;
  border-radius: 13px;
  box-shadow: 0px 2px 20px rgba(174, 174, 174, 0.1);
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.accordion__btn:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.accordion-icon {
  margin-right: 24px;
  max-width: 50px;
}

.accordion-desc {
  margin-right: 11px;
  font-size: 14px;
}

.services__table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}

.services__table-head {
  border-bottom: 2px solid var(--teamDarkGray);
}

.services__table-title {
  padding-bottom: 20px;
  max-width: 168px;
  text-align: left;
}

.services__table-desc {
  padding: 10px 15px;
  height: 68px;
  vertical-align: middle;
}

.service__table-item {
  margin-bottom: 5px;
}

.td-first {
  display: flex;
  align-items: center;
  max-width: 300px;
}

.td-fee {
  max-width: 200px;
}

.services__table-logo {
  max-width: 49px;
  border-radius: 50%;
  margin-right: 17px;
}

.services__table-row {
  border-bottom: 2.5px dashed var(--teamDarkGray);
}

.mobile {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .services__head {
    padding-bottom: 40px;
  }

  .accordion-title {
    font-size: 16px;
  }

  .services__list-box {
    margin-bottom: 25px;
    border-bottom: 2.5px dashed var(--teamDarkGray);
    position: relative;
  }

  .services__list-box:first-of-type::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 2px;
    background-color: var(--teamDarkGray);
    top: -15px;
  }

  .services__list-name {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 13px;
    width: 50%;
  }

  .services__list-text {
    font-size: 14px;
    margin-bottom: 23px;
    text-align: right;
  }

  .services__list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .accordion__content.active {
    max-height: 100%;
  }

  .accordion-icon {
    max-width: 36px;
  }
}
</style>
