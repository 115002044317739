<template>
  <div class="search__wrapper desktop">
    <form class="search__form" :class="{ hidden: !isOpen }" @submit.prevent.enter="handleSearch">
      <input
        class="search__input"
        type="text"
        id="search"
        :placeholder="$t('search.placeholder')"
        v-model="searchEntry"
        autocomplete="off"
      />
      <label class="search__label" for="search">
        <img src="@/assets/img/utils/search-dark.svg" alt="" />
      </label>
    </form>
    <button class="search__btn" aria-label="toggle search form" @click="toggleSearch">
      <img v-if="isOpen" src="@/assets/img/utils/close.svg" alt="" />
      <img v-else src="@/assets/img/utils/search.svg" alt="" />
    </button>
    <div class="search-result-container" ref="searchResults" v-if="results.length">
      <template v-for="result in results" :key="result.id">
        <HeaderSearchResult :title="result.title" :href="result.href" @click="resetSearchResults" />
      </template>
    </div>
  </div>
</template>

<script>
import searchData from '../../data/searches.json'
import HeaderSearchResult from '../Search/HeaderSearchResult.vue'
export default {
  name: 'Search Elemet',
  components: { HeaderSearchResult },
  data() {
    return {
      isOpen: true,
      results: [],
      availableSearches: searchData,
      searchEntry: '',
    }
  },
  methods: {
    toggleSearch() {
      this.isOpen = !this.isOpen
      this.results = []
    },
    resetSearch() {
      this.isOpen = false
    },
    handleSearch() {
      if (this.searchEntry !== '') {
        this.results = this.availableSearches.filter((res) => {
          if (res.title.toLowerCase().includes(this.searchEntry.toLowerCase().trim())) {
            return res
          }
        })
      }
    },
    resetSearchResults() {
      this.results = []
      this.searchEntry = ''
    },
  },
  mounted() {
    this.resetSearch()
  },
  watch: {
    searchEntry() {
      if (this.searchEntry === '' || !this.searchEntry) {
        this.results = []
      }
    },
  },
}
</script>

<style scoped>
.search__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-left: auto;
}
.search__form {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 244px;
  min-width: 180px;
  transition: transform 0.2s linear;
  position: relative;
}
.search__input {
  background-color: var(--teamSand);
  border: none;
  height: 36px;
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  padding: 0 30px 0 15px;
  color: var(--teamBlue);
  outline: none;
}
.search__label {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 6px;
  right: 0;
  padding: 0;
  margin: 0;
  width: 37px;
}
.search__btn {
  background: none;
  border: none;
  cursor: pointer;
  width: 37px;
  margin-left: auto;
  margin-top: 5px;
}

.hidden {
  transform: translateY(-200%);
  transition: transform 0.2s linear;
}

.search-result-container {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 30px;
  width: 300px;
  background: rgba(1, 66, 95, 0.5);
  padding: 10px;
  border-radius: 10px;
  height: 150px;
  overflow-y: scroll;
  scrollbar-color: var(--teamCyan) var(--teamSand);
  scrollbar-width: thin;
}
.search-result-container::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}
.search-result-container::-webkit-scrollbar-track {
  background: var(--teamSand);
}
.search-result-container::-webkit-scrollbar-thumb {
  background-color: var(--teamCyan);
  border-radius: 20px;
  border: 3px solid var(--teamCyan);
}

@media screen and (max-width: 1024px) {
  .desktop {
    display: none;
  }
  .search__btn {
    display: none;
  }
}
</style>
