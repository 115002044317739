<template>
  <div class="svg-icon">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      :width="width"
      :height="height"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="16" cy="15.9659" rx="16" ry="15.9659" :fill="bgColor" :fill-opacity="opacity" />
      <path
        d="M11.5677 8.77857C11.5674 9.25004 11.3793 9.7021 11.0446 10.0353C10.7099 10.3685 10.2561 10.5556 9.78295 10.5554C9.30984 10.5551 8.85621 10.3676 8.52184 10.0341C8.18748 9.70051 7.99976 9.24826 8 8.77679C8.00024 8.30532 8.1884 7.85325 8.52311 7.52004C8.85781 7.18683 9.31163 6.99976 9.78473 7C10.2578 7.00024 10.7115 7.18775 11.0458 7.5213C11.3802 7.85484 11.5679 8.3071 11.5677 8.77857ZM11.6212 11.8717H8.05352V23H11.6212V11.8717ZM17.2581 11.8717H13.7083V23H17.2225V17.1603C17.2225 13.9072 21.4769 13.605 21.4769 17.1603V23H25V15.9515C25 10.4674 18.703 10.6718 17.2225 13.365L17.2581 11.8717Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LinkedinIcon',
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    iconColor: {
      type: String,
      default: 'white',
    },
    opacity: {
      type: String,
      default: '0.16',
    },
  },
}
</script>

<style scoped>
.svg-icon {
  display: inline-block;
}
</style>
