<template>
  <router-link class="btn btn-link" :to="to" tag="button"
    >{{ title }}
    <img src="../../assets/img/utils/arrow-right.svg" alt="" />
  </router-link>
</template>

<script>
export default {
  name: 'Team Pay Button',
  props: {
    title: String,
    to: Object,
  },
}
</script>

<style scoped>
.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
</style>
