<template>
  <div class="owners">
    <div class="container">
      <div class="owners__inner">
        <h2 class="title fs24 tl pb45">{{ title }}</h2>
        <p class="desc fs16">
          {{ desc }}
        </p>
      </div>
      <p class="updated">{{ $t('updated') }} 14.03.2025</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Owners',
  props: {
    title: String,
    desc: String,
  },
}
</script>

<style></style>
