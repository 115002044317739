<template>
  <div class="reports">
    <div class="container">
      <div class="reports__inner">
        <h2 class="title fs24 tl pb45">{{ mainTitle }}</h2>
        <div class="documents__box">
          <div class="files" v-if="documents.length">
            <ul class="files__list">
              <li class="files__list-item" v-for="doc in documents" :key="doc.id">
                <img class="files__list-icon" src="@/assets/img/utils/file-icon.svg" alt="" />
                <a :href="doc.href" class="files__list-text" target="_blank">{{ doc.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="updated">{{ $t('updated') }} 27.06.2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reports',
  props: {
    mainTitle: String,
  },
  data() {
    return {
      documents: [
        {
          id: '1',
          title: '2021թ ֆինանսական հաշվետվություն',
          href: '/docs/reports/fsr_2021.pdf',
        },
        {
          id: '2',
          title: '2022թ ֆինանսական հաշվետվություն',
          href: '/docs/reports/fsr_2022.pdf',
        },
        {
          id: '3',
          title: '2023թ ֆինանսական հաշվետվություն',
          href: '/docs/reports/fsr_2023.pdf',
        },
      ],
    }
  },
}
</script>

<style></style>
